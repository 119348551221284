import { Playback } from '@iheartradio/web.playback';

import { amp } from './amp-client';

export async function isStationFollowed({
  contentId,
  type,
  userId,
}: {
  contentId: string | number;
  type: string;
  userId?: string | number;
}) {
  if (type === Playback.StationType.Podcast) {
    try {
      const { body: podcastData } = await amp.api.v3.podcast.getPodcast({
        params: { id: contentId },
      });
      return { followed: podcastData.follow, followable: true };
    } catch {
      return { followed: false, followable: false };
    }
  }

  if (type === Playback.StationType.Live) {
    try {
      const { status } = await amp.api.v3.profiles.getIsLiveStationFollowed({
        params: { liveStationId: contentId },
        throwOnErrorStatus: false,
      });
      return { followed: status === 204 ? true : false, followable: true };
    } catch {
      return { followed: false, followable: false };
    }
  }

  if (type === Playback.StationType.Artist) {
    try {
      const { status } = await amp.api.v3.profiles.getIsArtistFollowed({
        params: { artistId: contentId },
        throwOnErrorStatus: false,
      });
      return { followed: status === 204 ? true : false, followable: true };
    } catch {
      return { followed: false, followable: false };
    }
  }

  if (type === Playback.StationType.Playlist && userId) {
    try {
      const { body: playlistData } = await amp.api.v3.collection.getCollection({
        params: { userId, id: contentId },
      });
      return {
        followed: playlistData.followed,
        followable: playlistData.followable,
      };
    } catch {
      return { followed: false, followable: false };
    }
  }
}
