import { useMatches } from '@remix-run/react';

// This is intended to only be used exclusively by the Player
// Due to the Player not being rendered within a route, useLoaderData can not be used to pull the pageName value
// This utilizes useMatches to view the loader's data of the current route you are on
export const useGetPageName = () => {
  const matches = useMatches();
  const lastMatchData = matches.at(-1)?.data as { pageName?: string };
  return lastMatchData?.pageName ?? '';
};
